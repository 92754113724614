import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintWarg,
  mintPixaNFT,
  tokenURIfinder,
  checkPixaBalance,
  mintThreeWarg,
  checkWargsMinted,
  claimVortical,
  claimVorticalLoop,
  upgradeGem,
  checkDopplers
} from "./utils/interact.js";
import pixagif from 'D:/NFT_development/front_end/new-app-pages/wargs/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo.gif'
import pixapng from 'D:/NFT_development/front_end/new-app-pages/wargs/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo-2.png'
import wargsgif from 'D:/NFT_development/front_end/new-app-pages/wargs/nft-minter-tutorial-main/minter-starter-files/src/PixaWargs.gif'
import powergemgif from 'D:/NFT_development/front_end/new-app-pages/wargs/nft-minter-tutorial-main/minter-starter-files/src/powergem.gif'
import pleasewait from 'D:/NFT_development/front_end/new-app-pages/wargs/nft-minter-tutorial-main/minter-starter-files/src/pleasewait1.PNG'

const Minter = (props) => {

  function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setStatus("👆🏽 Write a message in the text-field above.");
      } else {
        setWallet("");
        setStatus("🦊 Connect to Metamask using the top button.");
      }
    });
  } else {
    setStatus(
      <p>
        {" "}
        🦊{" "}
        <a target="_blank" href={`https://metamask.io/download.html`}>
          You must install Metamask, a virtual Ethereum wallet, in your
          browser.
        </a>
      </p>
    );
    }
  }

  //State variables
  const [ownership, setAreYouOwner] = useState("");
  const [upgradestatus, setupgradeStatus] = useState("");
  const [pixaBalance, setPixaBalance] = useState("");
  const [walletAddress, setWallet] = useState("");
  const [wargs, setWargsMinted] = useState("");
  const [displayRank, setRank] = useState("");
  const [tokenID, setToken] = useState("");
  const [tokenURI, setTokenURI] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [wizstatus, setWizStatus] = useState(""); 
  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    const {currentwargsminted} = await checkWargsMinted();
    setWallet(address);
    setStatus(status);
    setWargsMinted(currentwargsminted)
    setTokenURI(powergemgif);
    setupgradeStatus("currently selected.")    
    const {walletpixabalance} = await checkPixaBalance(address)
    setPixaBalance(walletpixabalance)
    addWalletListener(); 
  }, []);


  const onTokenChange = async (num) => {
    setAreYouOwner("")
    setTokenURI(pleasewait)
    setRank("") 
    setAreYouOwner("please wait... ⏱️") 
    setToken(num)
    const { tokenURI, displayRank,ownership } = await tokenURIfinder(num, walletAddress);   
    setTokenURI("https://" + tokenURI.substring(7) + ".gif")
    setRank(displayRank)
    setAreYouOwner(ownership)
  };


  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintWargPressed = async () => { //TODO: implement
      const { status } = await mintWarg(); 
  };

  const onMintThreeWargPressed = async () => { //TODO: implement
      const { status } = await mintThreeWarg();
  };

  const onMintWargPixaPressed = async () => { //TODO: implement
      const { status } = await mintPixaNFT(walletAddress); 
  };

  const onClaimPressed = async () => { //TODO: implement
      const { status } = await claimVortical();
  };

  const onClaimLoopPressed = async () => { //TODO: implement
      const { status } = await claimVorticalLoop();
  };

  const onCheckPressed = async () => { //TODO: implement
      const { wizstatus } = await checkDopplers(name);
    setWizStatus(wizstatus);  
  };

  const onUpgrade1Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 1);
  };

  const onUpgrade2Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 2);
  };

  const onUpgrade3Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 3);
  };

  const onUpgrade4Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 4);
  };

  const onUpgrade5Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 5);
  };


  
  return (
    
    <div className="Minter">



<div id="stars"></div>
<div id="stars2"></div>
<div id="stars3"></div>


<h5>By using this webpage you agree to the terms & conditions outlined at the bottom of this page. Disclaimer: Use this site at your own risk.</h5>       

<h1>
          <div>
      <img src={pixapng} alt="Image" height={45} width={100} border={0}/>
      </div>
</h1>

<div id="menubar">
      <h2> <a href="https://wizards.pixanft.com" rel="noreferrer">  🧙‍♂️</a>  <a href="https://witches.pixanft.com" rel="noreferrer">  🧙‍♀️</a> <a href="https://wyverns.pixanft.com" rel="noreferrer">  🐲</a> <a href="https://wargs.pixanft.com" rel="noreferrer">  🐺</a>  <a href="https://brews.pixanft.com" rel="noreferrer">  🍺</a> <a href="https://limitededitions.pixanft.com" rel="noreferrer">  🖼️</a> </h2>
      <h5>Click an emoji to navigate to other pages!</h5>         
      <h2> <a href="https://tokens.pixanft.com" rel="noreferrer">  💰</a>  <a href="https://relics.pixanft.com" rel="noreferrer">  🗡️</a> <a href="https://wizardao.pixanft.com" rel="noreferrer">  🎩</a> <a href="https://tomes.pixanft.com" rel="noreferrer">  📖</a> <a href="https://land.pixanft.com" rel="noreferrer">  🏡</a> <a href="https://badges.pixanft.com" rel="noreferrer">  📛</a> </h2>
</div>          

      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>
  
      
      <h1 >🐺  The  🐺 </h1>
      <h1 >PixaWargs</h1>
      <h1>_________</h1>
<h4>The PixaWargs are majestic beasts that are the perfect companions for the powerful PixaWitches. These creatures are not to be underestimated, as they are known for their strength and fierce loyalty to their masters. There are only 500 of these limited edition Wargs, making them a rare and coveted addition to any collection. But that's not all - each PixaWarg also has an upgradable trait called the "Power Gem", allowing them to evolve and grow with their owner as they journey through the PixaRealm. Don't miss out on the opportunity to add one of these magnificent creatures to your wallet! For more background lore on the PixaRealm, visit our <a href="https://docs.pixanft.com" rel="noreferrer">docs page</a></h4>

<h4>The PixaWargs are ERC721 tokens minted on the Ethereum blockchain. They are the second "creature" NFT collection and were released in May 2022. For more information about the other NFT collections that exist in the PixaRealm, visit our <a href="https://pixanft.com" rel="noreferrer">  website</a></h4>

<h4> Scroll down to mint a PixaWarg NFT of your own, or check out the collection on <a href="https://opensea.io/collection/pixawargsofficial" rel="noreferrer">  OpenSea</a></h4>
<h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>


      <h1>Mint</h1>
      <h1>_________</h1>
      <h4>Mint a PixaWarg NFT using ETH or our utility token, $PIXA. </h4>
      <h4>ENSURE WALLET IS CONNECTED TO ETHEREUM</h4>

     

      <p>


      </p>
      
        <h1>
          <div>
      <img id="image" src={wargsgif} alt="Image" height={200} width={200} border={10} border-radius={20}/>
      </div>
</h1>

<h4>Total Minted: {wargs} / 500</h4>

      <button id="mintButton" onClick={onMintWargPressed}>
        Mint 1 (0.04 ETH)
      </button>
    <br>
      </br>   
      <button id="mintButton" onClick={onMintThreeWargPressed}>
        Mint 3 (0.08 ETH)
      </button>
    <br>
      </br>   
      <button id="mintButton" onClick={onMintWargPixaPressed}>
        Mint 1 (2000 $PIXA)
      </button>

      <h5> Wallet balance: {pixaBalance} <a href="https://etherscan.io/token/0xeaf211cD484118a23AD71C3F9073189C43d1311c" rel="noreferrer">  $PIXA</a></h5>

<h5>Note: When minting with $PIXA, you will have 2 transactions: 1) to approve the wargs contract to spend your $PIXA, and 2) the minting transaction. You only need to do the approval transaction once.</h5>

<h4> Check out your new PixaWarg on <a href="https://opensea.io/collection/pixawargsofficial" rel="noreferrer">  OpenSea</a> and join the community in <a href="https://discord.gg/pixa" rel="noreferrer">Discord</a></h4>


<h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>


<h1>Upgrade</h1>
<h1>_________</h1>
<h4>Spend $PIXA to upgrade the "Power Gem" collar of your PixaWarg NFT.</h4>
<h4>ENSURE WALLET IS CONNECTED TO ETHEREUM</h4>

<h1>
          <div>
      <img  id="image" src={tokenURI} alt="Image" height={200} width={200} border={10}/>

      </div>
</h1>

<h4>Select your PixaWarg tokenID:</h4>


      <input id="entry"
          type="number"
          placeholder="Warg #"
          onChange={(event) => onTokenChange(event.target.value)}
        />
<h4 id="warningmess"> PixaWarg{tokenID} is {upgradestatus}</h4>
      <h4 id="warningmess">Current Rank: {displayRank}</h4>
      <h4 id="warningmess">{ownership}</h4>


 

      <button id="upgrade1" onClick={onUpgrade1Pressed}>
        Zircon (100 $PIXA)
      </button>
      <br>
      </br> 
      <button id="upgrade2" onClick={onUpgrade2Pressed}>
        Topaz (250 $PIXA)
      </button>
      <br>
      </br> 
      <button id="upgrade3" onClick={onUpgrade3Pressed}>
        Garnet (500 $PIXA)
      </button>
      <br>
      </br> 
      <button id="upgrade4" onClick={onUpgrade4Pressed}>
        Jade (1000 $PIXA)
      </button>
      <br>
      </br> 
      <button id="upgrade5" onClick={onUpgrade5Pressed}>
        Rainbow (5000 $PIXA)
      </button>
      <h5> Wallet balance: {pixaBalance} <a href="https://etherscan.io/token/0xeaf211cD484118a23AD71C3F9073189C43d1311c" rel="noreferrer">  $PIXA</a></h5>
      <h5>Note: When upgrading with $PIXA, you will have 2 transactions: 1) to approve the wargs contract to spend your $PIXA, and 2) the minting transaction. You only need to do the approval transaction once.</h5>


      <h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

<h3>Important Links:</h3>

<h4><a href="https://etherscan.io/address/0x5b02557c4e9a5d886387e48fe3284668220afb53" rel="noreferrer">  PixaWargs Contract</a> </h4>

<h4><a href="https://linktr.ee/pixanft" rel="noreferrer">  Linktree</a> </h4>

<h4> <a href="https://opensea.io/PixaDeployer?tab=created" rel="noreferrer">  OpenSea Collections</a> </h4>

<h4><a href="https://discord.gg/pixa" rel="noreferrer">  Discord</a> </h4>

<h4><a href="https://twitter.com/pixa_nft" rel="noreferrer">  Twitter </a></h4>

<br></br>


<h3>Terms & Conditions:</h3>

<h9>(applies to all Pixa sites & smart contracts) - Use this site at your own risk. Pixa is not liable for any losses from the use of this site or associated smart contracts. Your purchase of Pixa NFTs does NOT CONSTITUTE AN INVESTMENT. Purchase of these NFTs from Pixa is meant for entertainment purposes only. Pixa makes no warranties regarding future value, express or implied, to the public or to buyers. Pixa does not offer refunds on purchased NFTs. Only purchase if you wish to own the artwork and nothing else. Only purchase with the current state of the project in mind and the assumption that no future value will be delivered. Pixa NFTs and $PIXA tokens are not currency and do not convey ownership in Pixa or its assets. You are purchasing a digital collectible, we do not guarantee any future development around these NFTs. $PIXA tokens are not shares or securities of any type. Holding a token does not entitle the holder to any ownership or other interest in Pixa (formerly known as PixaLyfe), future business operations, future revenue, or any control or ability to bind the entity. $PIXA tokens are merely a means by which you may be able to utilize certain services such as changing aspects of the artwork in your Pixa NFT. There is no guarantee that the PixaRealm platform will actually be developed, although we guarantee that it is our intention to do so.</h9>

<h1>
          <div>
      <img src={pixagif} alt="Image" height={200} width={200} border={0}/>
      </div>
</h1>

    </div>
    
  );
};



export default Minter;
